import React, { useState } from "react"
import Typist from 'react-typist';

const BenefitsTextCarousel = (props) => {
  const phrases = [
        `boost sales${props.punctuation === true ? '.' : ''}`, 
        `clear inventory${props.punctuation === true ? '.' : ''}`, 
        `conquer abandonment${props.punctuation === true ? '.' : ''}`, 
        `sell overstock${props.punctuation === true ? '.' : ''}`, 
        `wow customers${props.punctuation === true ? '.' : ''}`, 
        `increase turnover${props.punctuation === true ? '.' : ''}`, 
        `increase conversions${props.punctuation === true ? '.' : ''}`
    ]
  const [isTyping, changeIsTyping] = useState(true);
  const [textIndex, changeTextIndex] = useState(0)

  const doneTyping = () => {
    if (textIndex === phrases.length-1) {
      changeTextIndex(0);
      changeIsTyping(false);
      changeIsTyping(true);
      return;
    }
    changeTextIndex(textIndex+1);
    changeIsTyping(false);
    changeIsTyping(true);
  }

  return (
    isTyping ?
    <Typist onTypingDone={() => doneTyping()} className={`${props.inline === true ? 'inline-block' : ''}`}>
        <span>{phrases[textIndex]}</span>
        <Typist.Backspace count={phrases[textIndex].length} delay={400} />
    </Typist> : ''
  );
}

export default BenefitsTextCarousel;